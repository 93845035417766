import { createRouter, createWebHistory } from 'vue-router'
import RoutesCreator from './routes-creator.js'

// import NotFound           from '@/components/layout/NotFound.vue'

const Home                      = () => import(/* webpackChunkName: "Home" */                       '@/components/page/Home.vue')
const SiteMap                   = () => import(/* webpackChunkName: "SiteMap" */                    '@/components/page/SiteMap.vue')
const ItemWakeRingOverview      = () => import(/* webpackChunkName: "ItemWakeRingOverview" */       '@/components/page/item/wake-ring/Overview.vue')
const ItemWakeRingMerit         = () => import(/* webpackChunkName: "ItemWakeRingMerit" */          '@/components/page/item/wake-ring/Merit.vue')
const ItemWakeRingHowToUse      = () => import(/* webpackChunkName: "ItemWakeRingHowToUse" */       '@/components/page/item/wake-ring/HowToUse.vue')
const ItemWakeRingMovie         = () => import(/* webpackChunkName: "ItemWakeRingMovie" */          '@/components/page/item/wake-ring/Movie.vue')
const ItemWakeRingResearch      = () => import(/* webpackChunkName: "ItemWakeRingResearch" */       '@/components/page/item/wake-ring/Research.vue')
const ItemWakeRingColumn        = () => import(/* webpackChunkName: "ItemWakeRingColumn" */         '@/components/page/item/wake-ring/Column.vue')
const ItemWakeRingPatent        = () => import(/* webpackChunkName: "ItemWakeRingPatent" */         '@/components/page/item/wake-ring/Patent.vue')
const ServicePrintOverview      = () => import(/* webpackChunkName: "ServicePrintOverview" */       '@/components/page/service/print/overview/Overview.vue')
const ServicePrintPolicyTrading = () => import(/* webpackChunkName: "ServicePrintPolicyTrading" */  '@/components/page/service/print/policy/Trading.vue')
const ServicePrintPolicyPrivacy = () => import(/* webpackChunkName: "ServicePrintPolicyPrivacy" */  '@/components/page/service/print/policy/Privacy.vue')
const ServicePrintPolicyUser    = () => import(/* webpackChunkName: "ServicePrintPolicyUser" */     '@/components/page/service/print/policy/User.vue')
const ServicePrintPolicyPrice   = () => import(/* webpackChunkName: "ServicePrintPolicyPrice" */    '@/components/page/service/print/policy/Price.vue')
const ServicePrintPolicyPayment = () => import(/* webpackChunkName: "ServicePrintPolicyPayment" */  '@/components/page/service/print/policy/Payment.vue')
const ServicePrintHowToUse      = () => import(/* webpackChunkName: "ServicePrintHowToUse" */       '@/components/page/service/print/HowToUse.vue')
const ServiceNagasaMaiSecret    = () => import(/* webpackChunkName: "ServiceNagasaMaiSecret" */     '@/components/page/service/nagasa-mai/Secret.vue')
const ServiceNagasaMaiDetail    = () => import(/* webpackChunkName: "ServiceNagasaMaiDetail" */     '@/components/page/service/nagasa-mai/Detail.vue')
const ServiceNagasaMaiTakeout   = () => import(/* webpackChunkName: "ServiceNagasaMaiTakeout" */    '@/components/page/service/nagasa-mai/Takeout.vue')

const tree = {
  '': [[Home, 'ミヒラクストア'], {
    'services/print': [['/service/print/overview/']],
    'services/print/': [['/service/print/overview/']],
    'services/print/policies/trading': [['/service/print/policy/trading/']],
    'services/print/policies/trading/': [['/service/print/policy/trading/']],
    'services/print/policies/privacy': [['/service/print/policy/privacy/']],
    'services/print/policies/privacy/': [['/service/print/policy/privacy/']],
    'services/print/policies/user': [['/service/print/policy/user/']],
    'services/print/policies/user/': [['/service/print/policy/user/']],
    'services/print/price': [['/service/print/policy/price/']],
    'services/print/price/': [['/service/print/policy/price/']],
    'services/print/payment': [['/service/print/policy/payment/']],
    'services/print/payment/': [['/service/print/policy/payment/']],
    sitemap: [[SiteMap, 'サイトマップ']],
    item: {
      'wake-ring': [[null, 'わけりんぐ'], {
        overview:     [[ItemWakeRingOverview, '概要']],
        merit:        [[ItemWakeRingMerit, '仕組み と メリット']],
        'how-to-use': [[ItemWakeRingHowToUse, '使い方']],
        movie:        [[ItemWakeRingMovie, '動画']],
        research:         [[ItemWakeRingResearch, '有効性']],
        column:       [[ItemWakeRingColumn, 'コラム']],
        patent:       [[ItemWakeRingPatent, '特許']],
      }]
    },
    service: {
      print: [[null, 'プリント'], {
        overview:       [[ServicePrintOverview, '概要']],
        policy: {
          trading:      [[ServicePrintPolicyTrading, '特定商取引法に基づく表記']],
          privacy:      [[ServicePrintPolicyPrivacy, '個人情報の取り扱い']],
          user:         [[ServicePrintPolicyUser, '利用規約']],
          price:        [[ServicePrintPolicyPrice, '価格表']],
          payment:      [[ServicePrintPolicyPayment, 'お支払い方法']],
        },
        'how-to-use':   [[ServicePrintHowToUse, '使い方']],
      }],
      'nagasa-mai': [[null, '長狭米'], {
        secret: [[ServiceNagasaMaiSecret, '美味しく育つひみつ']],
        detail: [[ServiceNagasaMaiDetail, 'より詳しく']],
        takeout: [[ServiceNagasaMaiTakeout, 'お持ち帰りサービス']],
      }],
    },
  }],
}

const router = createRouter({
  history: createWebHistory(),
  routes: RoutesCreator.createRoutes(tree),
  scrollBehavior (to, from, savedPosition) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (savedPosition) resolve(savedPosition)
        else if (to.hash) {
          const headerElm = document.querySelector('.dummy-header')
          const headerHeight = headerElm ? headerElm.getBoundingClientRect().height : 0
          resolve({ el: to.hash, behavior: 'smooth', top: headerHeight + 10 })
        } else resolve({ left: 0, top: 0 })
      }, 100)
    })
  }
})

router.beforeEach(to => {
  document.title = to.meta.title || 'ドキュメント'
})

export default router
