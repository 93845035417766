<template>
  <w-toolbar shadow :style="{ visibility: layout.visibility }" :fixed="layout.fixed">
    <router-link to="/">
      <img src="/favicon.ico">
    </router-link>
    <div class="title2">
      {{ this.$waveui.breakpoint.name === 'xs' ? 'ドキュメント' : 'ミヒラクストア ドキュメント' }}
    </div>
    <div class="spacer"></div>
    <w-button @click="requestOpenDrawer" class="mdu-hide" icon="mdi mdi-text-box-multiple-outline" text lg></w-button>
  </w-toolbar>
</template>

<script>
export default {
  props: {
    dummy: Boolean,
  },
  computed: {
    layout() {
      return {
        visibility: this.dummy ? 'hidden': null,
        fixed: !this.dummy
      }
    },
  },
  methods: {
    requestOpenDrawer() {
      this.$emit('requestOpenDrawer')
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 30px;
  margin-right: 10px;
}
</style>
