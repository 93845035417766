let C
export default class RoutesCreator {
  static _isPlainObject(data) {
    if(
      data !== null
      && typeof(data) === 'object'
      && data.constructor === Object ) {
        return true
    }
    return false
  }
  static _getAttributes(value) {
    return Array.isArray(value) && Array.isArray(value[0]) ? value[0] : null
  }
  static _getComponent(value) {
    return (C._getAttributes(value) || [])[0] || null
  }
  static _getTitle(value) {
    return (C._getAttributes(value) || [])[1] || null
  }
  static _getSubTree(value) {
    if (C._isPlainObject(value)) return value
    if (Array.isArray(value) && C._isPlainObject(value[1])) return value[1]
  }
  static _createTitle(nodeTitle, baseTitle) {
    if (!nodeTitle) return baseTitle
    return `${nodeTitle}${baseTitle ? '｜' : ''}${baseTitle}`
  }
  static _createRoute(path, component, title) {
    if (typeof(component) === 'string') {
      return { path, redirect: component }
    } else {
      // console.log(`https://doc.mihiraku.com${path}`)
      return { path, component, meta: { title }}
    }
  }
  static _process(routes, nodeName, component, nodeTitle, subTree, basePath, baseTitle) {
    const path = `${basePath}${nodeName}/`
    const title = C._createTitle(nodeTitle, baseTitle)
    if (component) {
      const route = C._createRoute(path, component, title)
      routes.push(route)
    }
    if (subTree) C._collect(routes, subTree, path, title)
  }
  static _collect(routes, tree, basePath = '', baseTitle = '') {
    for (const nodeName in tree) {
      const value = tree[nodeName]
      const component = C._getComponent(value)
      const nodeTitle = C._getTitle(value)
      const subTree = C._getSubTree(value)
      C._process(routes, nodeName, component, nodeTitle, subTree, basePath, baseTitle)
    }
  }
  static createRoutes(tree) {
    const routes = []
    C._collect(routes, tree)
    return routes
  }
}
C = RoutesCreator
