<template>
  <w-app>
    <div class="top">
      <div class="header">
        <Header @requestOpenDrawer="requestedOpenDrawer"/>
      </div>
      <w-drawer v-model="openDrawer" width="75%" fit-content>
        <SiteMap/>
      </w-drawer>
      <w-flex class="column">
        <div class="dummy-header">
          <Header :dummy="true"></Header>
        </div>
        <w-flex>
          <div class="smd-hide"><SiteMap/></div>
          <div class="grow">
            <div class="view">
              <router-view/>
            </div>
          </div>
          <div></div>
        </w-flex>
        <div><Footer/></div>
      </w-flex>
    </div>
  </w-app>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import SiteMap from '@/components/layout/SiteMap.vue'
export default {
  components: {
    Header,
    Footer,
    SiteMap,
  },
  data() {
    return {
      openDrawer: false,
    }
  },
  watch: {
    $route() {
      this.openDrawer = false
    }
  },
  methods: {
    requestedOpenDrawer() {
      this.openDrawer = true
    }
  }
}
</script>

<style lang="scss">
h1, h2, h3 {
  color: #2c3e50;
}
a {
  font-weight: bold;
  color: #42b983;
}
.site-map {
  a.router-link-exact-active {
    border-bottom: solid;
    border-width: 3px;
    border-color: yellow;
  }
}
i.w-icon {
  vertical-align: text-bottom;  // 文章とアイコンを揃える
}
.page.policy {
  color: #757575;
  h1, h2 {
    color: #656565;
  }
}
.page {
  text-align: center;
  h1 {
    margin-top: 50px;
    overflow-wrap: break-word;
    word-break: keep-all;
  }
  .next-link {
    padding-top: 50px;
    text-align: center;
  }
  p.next-comment {
    padding-top: 50px;
    text-align: center;
    line-height: 200%;
  }
  .content {
    margin-top: 20px;
    padding: 0 20px;
    line-height: 200%;
    &>h2:first-child, &>p:first-child, &>pre:first-child, &>img:first-child, &>iframe:first-child, &>table:first-child, &>.w-card:first-child {
      margin-top: 50px;
    }
    &>.w-card:first-child, &>.w-image-wrap:first-child {
      margin-top: 50px;
    }
    p+.w-image-wrap, p+.w-flex {
      margin-top: 30px;
    }
    h2 {
      margin-top: 40px;
      overflow-wrap: break-word;
      word-break: keep-all;
    }
    p, pre, iframe, table, .w-card, img:not(.w-image) {
      margin-top: 30px;
    }
    p {
      .w-image-wrap {
        margin: 30px 10px;
      }
      .w-card {
        margin: 30px;
      }
      .w-divider {
        margin-top: 30px;
      }
    }
    .w-card {
      overflow-wrap: break-word;
      word-break: keep-all;
    }
    h2, p, pre, ul, ol {
      text-align: left;
    }
    p.center {
      text-align: center;
    }
    p.no-margin-top {
      margin-top: 0;
    }
    img, iframe {
      max-width: 100%;
    }
    ul, ol {
      margin-top: 20px;
      margin-left: 30px;
      &.no-point {
        list-style-type: none;
        margin-left: 0;
      }
      &.reference {
        line-height: 125%;
        font-size: 75%;
      }
    }
    pre {
      font-family: auto;
      white-space: pre-wrap;
    }
    table.border {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      th, td {
        border: 1px solid #d6d6d6;
        padding: 10px 0;
      }
    }
    .nest-order {
      &>ol {
        margin-left: 10px;
      }
      ol {
        counter-reset: nest;
        list-style-type: none;
      }
      ol>li:before {
        counter-increment: nest;
        content: counters(nest, ".") ". ";
      }
      ol>li>ol {
        margin-top: 0;
      }
    }
    hr {
      margin: 50px 0;
    }
    .w-button {
      padding: 20px;
    }
    b {
      padding: 0 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
.top {
  font-family: 'Avenir', 'Helvetica', 'Arial', 'sans-serif', 'Meiryo', 'Hiragino Maru Gothic ProN W4', 'Hiragino Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #38464c;
  .grow {
    overflow: hidden;
  }
  .view {
    max-width: 48rem;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
