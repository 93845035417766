<template>
  <div class="top">
    <w-flex class="justify-space-evenly wrap">
      <a href="//mihiraku.com" target="_blank" rel="noopener noreferrer">mihiraku.com</a>
      <a href="//print.mihiraku.com" target="_blank" rel="noopener noreferrer">print.mihiraku.com</a>
      <a href="//minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">minne.com/@mihiraku</a>
    </w-flex>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.top {
  margin-top: 100px;
  margin-bottom: 50px;
  a {
    color: #c1c1c1;
  }
}
</style>
