<template>
  <div class="site-map" :class="{ center }">
    <ul class="left">
      <li><router-link to="/"><w-icon xl>mdi mdi-home-circle</w-icon></router-link></li>
      <li><router-link to="/sitemap/">サイトマップ</router-link></li>
    </ul>
    <h3>お米のお持ち帰り</h3>
    <ul>
      <li><router-link to="/service/nagasa-mai/secret/">美味しく育つ ひみつ</router-link></li>
      <li><router-link to="/service/nagasa-mai/detail/">より 詳しく</router-link></li>
      <li><router-link to="/service/nagasa-mai/takeout/">お持ち帰り サービス</router-link></li>
    </ul>
    <h3>わけりんぐ</h3>
    <ul>
      <li><router-link to="/item/wake-ring/overview/">わけりんぐ とは？</router-link></li>
      <li><router-link to="/item/wake-ring/merit/">仕組みと 効果</router-link></li>
      <li><router-link to="/item/wake-ring/research/">有効性の 調査</router-link></li>
      <li><router-link to="/item/wake-ring/how-to-use/">シミュレーション <w-icon>mdi mdi-cube-scan</w-icon></router-link></li>
      <li><router-link to="/item/wake-ring/movie/">詳しい 動画</router-link></li>
      <li><a href="https://minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">ショップ <w-icon>mdi mdi-open-in-new</w-icon></a></li>
      <li><router-link to="/item/wake-ring/patent/">特許</router-link></li>
    </ul>
    <h3>漢字プリントサービス</h3>
    <ul>
      <li><router-link to="/service/print/overview/">概要</router-link></li>
      <li><router-link to="/service/print/policy/trading/">特定商取引法に基づく表記</router-link></li>
      <li><router-link to="/service/print/policy/privacy/">個人情報の取り扱い</router-link></li>
      <li><router-link to="/service/print/policy/user/">利用規約</router-link></li>
      <li><router-link to="/service/print/policy/price/">価格表</router-link></li>
      <li><router-link to="/service/print/policy/payment/">お支払い方法</router-link></li>
      <li><router-link to="/service/print/how-to-use/">使い方</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    center: Boolean
  }
}
</script>

<style lang="scss" scoped>
.site-map {
  margin-left: 16px;
  padding-right: 10px;
  overflow-y: auto;
  h3.home {
    margin-top: 24px;
  }
  h3 {
    margin-top: 32px;
  }
  ul {
    margin-top: 16px;
    margin-left: 16px;
    &.left {
      margin-left: 0;
    }
  }
  li {
    list-style-type: none;
    margin-top: 16px;
  }
  &.site-map.center {
    margin-left: 0;
    ul {
      margin-left: 0;
    }
  }
}
</style>
