import { createApp } from 'vue'
import Layout from './components/layout/Layout.vue'
import router from './router/router.js'
import store from './store/store.js'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'

// ページ読み込みが軽くなるようにアイコンはCDNに変更した
// import '@mdi/font/css/materialdesignicons.min.css'

const app = createApp(Layout)
new WaveUI(app, {})
app.use(store).use(router).mount('#app')
